import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogPage5 from "../components/BlogPage/BlogPage5"
import BlogPage5All from "../components/BlogPage/BlogPage5All"
import BlogContents from '../components/BlogPage/BlogContents'
import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    
    <LayoutBlog>
      <Helmet>
        <meta property="og:title" content="Top 5 Luxury Customer Trends for Luxury Fund Managers" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/X7pfVIVkb5spShBhLM5KJ/9da28c111ef4a96bdfa102b29a1b390d/funding.png" />
        <meta property="og:description" content="China became the second biggest luxury market in 2023 and luxury’s leading brands success or failure is increasingly dependant on their performance in this market." />
        <meta property="og:url" content="https://thefreshkid.com/luxury-customeranalysis/" />
      </Helmet>
      <BlogPage5/>
      <BlogPage5All/>
    </LayoutBlog>
  )
}

export default blog





