import React, { useState, useEffect } from "react";
import "../scss/BlogPage5All.scss";
import NewsletterBar from "../NewsletterBar"
import Footer from "../Footer"
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import bof from './bof.png';
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';
import book1 from './book1111.png';
import book2 from './book2222.png';
import book3 from './book3333.png';
import book4 from './book4444.png';
import book5 from './book5555.png';
import leftArrow from './left.png';
import rightArrow from './right.png';
import { DiscussionEmbed } from "disqus-react";


const BlogPage5All = () => {

    const data = useStaticQuery(graphql`
    query MyQueryFundManager {
        text1: allContentfulTextBlock(filter: { id: { eq: "3cfeef97-27d0-50b2-aac3-b9ce55d15e99" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text2: allContentfulTextBlock(filter: { id: { eq: "ee251262-2a76-5634-b570-a5e74464cf4f" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text3: allContentfulTextBlock(filter: { id: { eq: "c90c1598-ddf8-5444-87ef-1ebbfec619d7" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text4: allContentfulTextBlock(filter: { id: { eq: "b60abadd-d851-5206-a457-ee42642c216b" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        text5: allContentfulTextBlock(filter: { id: { eq: "332d85ab-54a4-5f20-8de5-d1efc17f833e" } }) {
          edges {
            node {
              bodyText {
                raw
              }
              title
            }
          }
        }
        pic1: allContentfulImageBlock(filter: { name: { eq: "Blog 5 Image 1" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic2: allContentfulImageBlock(filter: { name: { eq: "Blog 5 Image 2" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic3: allContentfulImageBlock(filter: { name: { eq: "third image for blog 5" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic4: allContentfulImageBlock(filter: { name: { eq: "Blog 5 Image 4" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic5: allContentfulImageBlock(filter: { name: { eq: "Blog 5 Image 5" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }
        pic6: allContentfulImageBlock(filter: { name: { eq: "latin" } }) {
          edges {
            node {
              image {
                altText
                image {
                  file {
                    url
                  }
                }
              }
              name
            }
          }
        }



    }
  `);


  const t1 = data.text1.edges[0].node;
  const t2 = data.text2.edges[0].node;
  const t3 = data.text3.edges[0].node;
  const t4 = data.text4.edges[0].node;
  const t5 = data.text5.edges[0].node;
  const p1 = data.pic1.edges[0].node;
  const p2 = data.pic2.edges[0].node;
  const p3 = data.pic3.edges[0].node;
  const p4 = data.pic4.edges[0].node;
  const p5 = data.pic5.edges[0].node;
  const p6 = data.pic6.edges[0].node;



  const options = {
    renderText: (text) => {
      // Split the text based on the word you want (e.g., "breakpoint-word")
      const breakpointWord = "calledp";
      const splitText = text.split(breakpointWord);
  
      // Create an array of React components
      const reactComponents = splitText.flatMap((text, index) => [
        // Add a <br> tag after the word in all elements except the last one
        <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
        index !== splitText.length - 1 && <br key={`br-${index}`} />,
      ]);
  
      return <React.Fragment>{reactComponents}</React.Fragment>;
    },
  };



    const [activeIndexes, setActiveIndexes] = useState([]);
  
    const handleClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((i) => i !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };





    // const [activeSection, setActiveSection] = useState("");

    // const sectionIds = [
    //   "It's all in your mind",
    //   "Nurture and Protect",
    //   "Exercise",
    //   "You are what you read",
    //   "Sleep and rest Well",
    // ];
  
    // useEffect(() => {
    //   const handleScroll = () => {
    //     const sectionOffsets = sectionIds.map((sectionId) => {
    //       const sectionElement = document.getElementById(sectionId);
    //       return {
    //         id: sectionId,
    //         offsetTop: sectionElement.offsetTop,
    //         offsetBottom: sectionElement.offsetTop + sectionElement.offsetHeight,
    //       };
    //     });
    
    //     const currentScrollPosition = window.scrollY + window.innerHeight / 2;
    
    //     for (const { id, offsetTop, offsetBottom } of sectionOffsets) {
    //       if (currentScrollPosition >= offsetTop && currentScrollPosition <= offsetBottom) {
    //         setActiveSection(id);
    //         break;
    //       }
    //     }
    //   };
    
    //   window.addEventListener("scroll", handleScroll);
    
    //   return () => {
    //     window.removeEventListener("scroll", handleScroll);
    //   };
    // }, []);


    


    

    const [isContentsVisible, setIsContentsVisible] = useState(false);

    useEffect(() => {
      Aos.init({ duration: 2000 });
    
      const showThreshold = 500; // Adjust this value to determine when to show the contents
      const hideThreshold = 10000; // Adjust this value to determine when to hide the contents
      let isScrollingDown = false;
    
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
    
        if (scrollPosition > showThreshold && !isScrollingDown) {
          setIsContentsVisible(true);
          isScrollingDown = true;
        } else if (scrollPosition <= showThreshold && isScrollingDown) {
          setIsContentsVisible(false);
          isScrollingDown = false;
        }
    
        // Add logic to hide content near the bottom
        if (scrollPosition > hideThreshold) {
          setIsContentsVisible(false);
        }
      };
    
      window.addEventListener("scroll", handleScroll);
    
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handleArrowClick = () => {
      // Scroll down 300 pixels when the arrow is clicked
      window.scrollBy({
        top: 400,
        behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
      });
    };

    const images = [
      book1, book2, book3, book4, book5,
    ];

    const texts = [
      ['Radical Candour -  Silicon Valley Technology Leadership Handbook', 'If you are responsible for the performance of a team especially technology teams this book is invaluable.'],
      ['Rich Dad Poor Dad -  Investment Mindset Advice', 'A good place to start for those starting on their investment journey.'],
      ['The Art of Being and Becoming - Personal Development', 'A belief system of how to conduct yourself at work and in life.'],
      ['The Aficionados -  Luxury Travel and Design', 'The world’s most tasteful hotels, designers and tastemakers curated'],
      ['Monocle  -  Design, Travel and Taste', 'For all things related to good taste'],
    ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
  
    const prevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const disqusConfig = {
      shortname: "tfk-1",
      config: {
      },
      language: "en",
    };

 



  return (
    <>
      <Helmet>
        <title>Top 5 Luxury Customer Trends for Luxury Fund Managers</title>
        <meta name="description" content="De-Risk and Protect Luxury Investments - Discover Top 5 Luxury Customer Trends for Luxury Fund Managers" />
        <meta name="keywords" content="luxury customer analysis, luxury investor" />
        <h1>Top 5 Luxury Customer Trends or Luxury Fund Managers</h1>
      </Helmet>
    <div className={`all1`}>
    <div className={`check1`}>



    {/* {isContentsVisible && (
        <div>
          <div className="contentsListads">
            <p className="contentads">Contents</p>
            {sectionIds.map((sectionId) => (
              <a
                key={sectionId}
                // href={`#${sectionId}`}
                className={activeSection === sectionId ? "active" : ""}
              >
                {sectionId}
              </a>
            ))}
          </div>
        </div>
      )} */}





      <Helmet>
        <h2>The Unstoppable Rise of Chinese Luxury Customers</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="FundManagerBlogContainer">
          <div className="textContainerFundManagerBlog">
            <p className="FundManagerBlogNo1">1.</p>
            {t1.bodyText && (
              <div className="titleFundManagerBlogNo1">
                The Unstoppable Rise of Chinese Luxury Customers
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogPicture1">
          <div className={"imageContainerFundManagerBlogPicture1"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury customer analysis"
              key={""}
              src={p1.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="FundManagerBlogContainerText">
          <div className="bodyTextContainerFundManagerBlog">
            {t1.bodyText && (
              <div className="bodyFundManagerBlogNo1">
                {documentToReactComponents(JSON.parse(t1.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogFund1'}>
              <img
                className={'arrowFund'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>







      <Helmet>
        <h2>A Complete Guide to Chinese Luxury Customers Spending 2023</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="FundManagerBlogContainer2">
          <div className="textContainerFundManagerBlog2">
            <p className="FundManagerBlogNo2">2.</p>
            {/* {t2.bodyText && (
              <div className="titleFundManagerBlogNo2">
                A Complete Guide to Chinese Luxury Customers Spending 2023
              </div>
            )} */}
          </div>
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogPicture2">
          <div className={"imageContainerFundManagerBlogPicture2"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury customer behaviour analysis"
              key={""}
              src={p2.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="FundManagerBlogContainerText2">
          <div className="bodyTextContainerFundManagerBlog2">
            {t2.bodyText && (
              <div className="bodyFundManagerBlogNo2">
                {documentToReactComponents(JSON.parse(t2.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogFund2'}>
              <img
                className={'arrowFund'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>








      <Helmet>
        <h2>Top 3 Highest Spending Luxury Customers</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="FundManagerBlogContainer3">
          <div className="textContainerFundManagerBlog3">
            <p className="FundManagerBlogNo3">3.</p>
            {t3.bodyText && (
              <div className="titleFundManagerBlogNo3">
                Top 3 Highest Spending Luxury Customers
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogPicture3">
          <div className={"imageContainerFundManagerBlogPicture3"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="customer behaviour analysis"
              key={""}
              src={p3.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="FundManagerBlogContainerText3">
          <div className="bodyTextContainerFundManagerBlog3">
            {t3.bodyText && (
              <div className="bodyFundManagerBlogNo3">
                {documentToReactComponents(JSON.parse(t3.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogFund3'}>
              <img
                className={'arrowFund'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>










      <Helmet>
        <h2>Luxury’s Top Spenders Purchase and Invest in Luxury Products</h2>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="FundManagerBlogContainer4">
          <div className="textContainerFundManagerBlog4">
            <p className="FundManagerBlogNo4">4.</p>
            {t4.bodyText && (
              <div className="titleFundManagerBlogNo4">
                Luxury’s Top Spenders Purchase and Invest in Luxury Products
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogPicture4">
          <div className={"imageContainerFundManagerBlogPicture4"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury customer behaviour analysis"
              key={""}
              src={p4.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="FundManagerBlogContainerText4">
          <div className="bodyTextContainerFundManagerBlog4">
            {t4.bodyText && (
              <div className="bodyFundManagerBlogNo4">
                {documentToReactComponents(JSON.parse(t4.bodyText.raw))}
              </div>
            )}
          </div>
          <div className={'arrowContainerBlogFund4'}>
              <img
                className={'arrowFund'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div>
        </div>
      </div>





      <Helmet>
        <h2>Luxury Customer Experience is Everything</h2>
        <h3>Negative Customer Experiences are Expensive</h3>
        <h3>Speed and Convenience</h3>
      </Helmet>
      <div>
        <div id="It's all in your mind" className="FundManagerBlogContainer5">
          <div className="textContainerFundManagerBlog5">
            <p className="FundManagerBlogNo5">5.</p>
            {t5.bodyText && (
              <div className="titleFundManagerBlogNo5">
                Luxury Customer Experience is Everything
              </div>
            )}
          </div>
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogPicture5">
          <div className={"imageContainerFundManagerBlogPicture5"}>
            <img
              data-aos="fade-up"
              className={"bigImage"}
              alt="luxury customer experience analysis"
              key={""}
              src={p5.image.image.file.url}
            />
          </div>
        </div>
      </div>


      <div>
        <div className="FundManagerBlogContainerText5">
          <div className="bodyTextContainerFundManagerBlog5">
            {t5.bodyText && (
              <div className="bodyFundManagerBlogNo5">
                {documentToReactComponents(JSON.parse(t5.bodyText.raw))}
              </div>
            )}
          </div>
          {/* <div className={'arrowContainerBlogFund5'}>
              <img
                className={'arrowFund'}
                src={arrow}
                alt="arrow"
                onClick={handleArrowClick} // Attach the click handler here
              />
          </div> */}
        </div>
      </div>


      <div>
        <div className="containerFundManagerBlogLatin">
          <div className={"imageContainerFundManagerBlogLatin"}>
            <img
              className={"bigImage"}
              key={""}
              src={p6.image.image.file.url}
            />
          </div>
        </div>
      </div>












      <div>
      <div className="comment-box-titleFund">Join the Conversation</div>
      <div className="comment-box-containerFund">
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </div>


      
    </div>
    {/* <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div> */}
{/*       
      <Footer /> */}
    </div>
    </>
  );
}

export default BlogPage5All;



